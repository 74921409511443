import {
  useState,
  Fragment,
  useContext,
  useEffect,
  useDeferredValue,
  startTransition,
} from "react";
import useProductResultPage from "../../hooks/useProductResultPage";
import { usePrpStore } from "../../contexts/prpContext";
import PanelContext from 'Clutch/Contexts/PanelContext'
import Facet from "./facet/facet";
import Skeleton from "Clutch/Atoms/Skeleton";
import SortTypeFacet from "./sortTypeFacet/sortTypeFacet";
import FacetOption from "./facet/facetOption/facetOption";
import Drawer from "Clutch/Molecules/NewDrawer/Drawer";
import Panel from "Clutch/Atoms/Panel";
import Divider from "Clutch/Atoms/Divider";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import Button from "Clutch/Molecules/Button";
import FacetOptionSwitch from "./facet/facetOptionSwitch/facetOptionSwitch";
import styles from "./sidebarDrawer.module.scss";
import ZipCodeInput from "./zipCodeInput/zipCodeInput";
import {
  setSidebarOpen,
  getSidebarOpen,
} from "../../Utilities/LocalStorage/sidebarDrawer";
import SidebarDrawerFacets from "./sidebarDrawerFacets/sidebarDrawerFacets";
import GarageControls from "./gargageControls/garageControls";
import ShareButton from "./shareButton/shareButton";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import defer from "Clutch/Utilities/defer"

const SidebarDrawer = () => {
  const OpenSidebarDrawer = usePrpStore((x) => x.OpenSidebarDrawer);
  const CloseSidebarDrawer = usePrpStore((x) => x.CloseSidebarDrawer);
  const isOpen = usePrpStore(x => x.uiState.sidebarDrawerOpen)
  const isMobile = usePrpStore(x => x.isMobile)

  useEffect(() => {
    if (getSidebarOpen()) {
      OpenSidebarDrawer();
    }
  }, []);


  if(!isMobile){
    return false;
  }


  return (
    <Drawer
      location={"right"}
      
      isOpen={isOpen}
      onClose={CloseSidebarDrawer}
      testId={"sidebar_drawer"}
      portalId={"sidebar_drawer"}
      removeWhenClosed={false}

      title={'Sort & Facet'}
      footer={<DrawerFooter/>}
    >
      <PanelContext.Provider value={{layer: '2'}}>
        <SidebarDrawerContents isOpen={isOpen} />
      </PanelContext.Provider>
    </Drawer>
  );
};

const DrawerFooter = () => {
  const totalSearchResults = useProductResultPage((x) => x.totalSearchResults);
  const CloseSidebarDrawer = usePrpStore((x) => x.CloseSidebarDrawer);

  const showResultsText = totalSearchResults
    ? totalSearchResults == 1
      ? `Show 1 Result`
      : `Show ${totalSearchResults} Results`
    : "Show Results";

  return (
    <Button
      segmentEvent={createClickedSegmentEvent("SidebarShowResults")}
      onClick={CloseSidebarDrawer}
      brand={"primary"}
      size={"small"}
      fill
      text={showResultsText}
    />
  )
}


function SidebarDrawerContents({ isOpen }) {
  const LoadLazyFacets = usePrpStore((x) => x.LoadLazyFacets);
  const skeleton = useProductResultPage(x => x.skeleton)

  useEffect(() => {
    //handles both drawer open on load and drawer opened by user click
    if (!skeleton && isOpen) {
      LoadLazyFacets();
    }
  }, [skeleton, isOpen]);

  const isFacetResultPage = useProductResultPage((x) => x.isFacetResultPage);

  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions
  );

  

  const selectedFacets = selectedFacetOptions || [];

  // search response should not change from SearchQuery in the future, but it shouldn't count as a searchResponseFacet
  const filterFacetsCount = selectedFacets?.filter(
    (currentFacet) => currentFacet?.searchResponseFacetOptions
  ).length;
  const facetsSelected = filterFacetsCount > 0;
  const selectedFacetText = facetsSelected ? `(${filterFacetsCount})` : "";



  const onFacetSelect = () => {
    setSidebarOpen();
  };

  return (
    <div className={styles.sidebar_drawer} data-testid={"navigation_mobile"}>
      <div className={styles.body}>
        {isFacetResultPage ? null : <SortTypeFacet />}

        {facetsSelected && (
          <div>
            <Divider className={styles.divider} />
            <div className={styles.filterHeader}>
              <Typography font={"bold"} tone={"contrast"}>
                {`Filters ${selectedFacetText}`}
              </Typography>
              <TextLink
                intent={"action"}
                size={0.875}
                font={"bold"}
                href={"/shop/all"}
                onClick={() => console.log("everything cleared!")}
                nofollow
                data-testid={"clear_all_sidebar_drawer"}
              >
                Clear All
              </TextLink>
            </div>
            <SidebarDrawerFacets />
          </div>
        )}
        <GarageControls />

        <FacetOptionSwitch
          brand={"primary"}
          facetName={"Fitment Filter"}
          facetOptionValueId={1}
          displayNameOverride={"Hide Universal Fit"}
          dividerTop
          onClick={onFacetSelect}
        />
        <FacetOptionSwitch
          facetName={"Availability"}
          facetOptionValueId={-1}
          displayNameOverride={"In Stock & Ships Fast"}
          dividerTop={true}
          onClick={onFacetSelect}
          brand={"secondarypalette_purple"}
        />
        <ZipCodeInput dividerTop />
        <ShareButton dividerTop />

        <GiantListOfFacetsWithOptions
          isOpen={isOpen}
          onFacetSelect={onFacetSelect}
        />

        <FacetOptionSwitch
          facetName={"Discounts"}
          facetOptionValueId={3}
          dividerTop={true}
          onClick={onFacetSelect}
          brand={"secondarypalette_purple"}
        />
      </div>
    </div>
  );
}

function GiantListOfFacetsWithOptions({ isOpen, onFacetSelect }) {
  const [isOpenDeferred, setIsOpenDeferred] = useState(false);

  useEffect(() => {
    // note: useDeferredValue seems to have a slightly different behavior than doing it this way
    // it's a little laggier than I like.

    defer(() => {
      setIsOpenDeferred(isOpen);
    }) 
    
  }, [isOpen]);

  const searchResponseFacets = useProductResultPage(
    (x) => x.searchResponseFacets
  );

  const showLoadingState = !isOpen || !isOpenDeferred;


  if (showLoadingState) {
    return false // todo use skeletons here-- for some reason these get stuck on a prod build (but not a dev build)
  }

  return (
    <>
      {(searchResponseFacets || []).map((facet, i) =>
        facet.facetName === "Ships for Free" ||
        facet.facetName === "Rebates" ? (
          facet.searchResponseFacetOptions.find((x) => x.id == 1) ==
          null ? null : (
            <Fragment key={`sidebar_facet_option_${facet.facetName}_${i}`}>
              <Divider className={styles.divider} />
              <FacetOption
                facetName={facet.facetName}
                facetOption={facet.searchResponseFacetOptions.find(
                  (x) => x.id === 1
                )}
                isMultiSelect={false}
                hidden={false}
                onClick={onFacetSelect}
              />
            </Fragment>
          )
        ) : (
          <div
            className={styles.facetGroup}
            key={`sidebar_drawer_wrapper_${facet.facetName}_${i}`}
          >
            <Divider className={styles.divider} />
            <Facet facet={facet} onFacetOptionClick={onFacetSelect} displayedInSidebarDrawer />
          </div>
        )
      )}
    </>
  );
}

export default SidebarDrawer;
