import { useState, useEffect } from 'react'
import { RecommendationProvider } from 'Stores/RecommendationStore/RecommendationStore'
import { usePrpStore } from '/contexts/prpContext'
import styles from './recommendations.module.scss'

import useProductResultPage from '../../hooks/useProductResultPage'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import RecommendationSection from 'Clutch/Organisms/RecommendationSection'
import {useRecommendationStore} from 'Stores/RecommendationStore/RecommendationStore'
import defer from 'Utilities/defer'

const Recommendations = () => {

  const skeleton = useProductResultPage(x => x.skeleton)
  const isNoResultPage = useProductResultPage(x => x.isNoResultPage)
  const displayTitle = useProductResultPage(x => x.displayTitle)
  const searchProducts = useProductResultPage(x => x.searchProducts)
  const spaLoading = usePrpStore(x => x.uiState.spaLoading)
  const sidebarDrawerOpen = usePrpStore(x => x.uiState.sidebarDrawerOpen)
  const [skuBasesToSend, setSkuBasesToSend] = useState([])
  
  useEffect(() => {
    // defer updating recommendations until the sidebar is closed
    if(!sidebarDrawerOpen){
      defer(() => {
        setSkuBasesToSend((searchProducts ?? []).map(product => product.skuBaseNumber))
      })
    }
  }, [searchProducts, sidebarDrawerOpen])

  const sectionName = isNoResultPage ? "NRP" : "PRP_Bottom"

  return (
    <div >
      <ErrorBoundary>
        <RecommendationProvider
          sections={[sectionName]}
          keywords={displayTitle}
          isPageLoading={skeleton || spaLoading}
          skuBaseNumbers={skuBasesToSend}>
            <RecommendationsInner sectionName={sectionName} displayTitle={displayTitle} skeleton={skeleton} spaLoading={spaLoading}/>
        </RecommendationProvider>
      </ErrorBoundary>
    </div>
  )
}

const RecommendationsInner = ({sectionName, displayTitle, skeleton, spaLoading}) => {
  const setIsPageLoading = useRecommendationStore(x => x.setIsPageLoading)
  const setSections = useRecommendationStore(x => x.setSections)

  useEffect(() => {
    setSections([sectionName])
  }, [sectionName])

  useEffect(() => {
    setIsPageLoading(skeleton || spaLoading)
  }, [skeleton, spaLoading])

  return (
    <RecommendationSection extendGuttersForCarousel section={sectionName} numberOfSkeletons={3} numberOfSkeletonSections={3} skeletonDisplayType={"Carousel"} displayType={"Carousel"} spacingSize={'Jumbo-SM'} keywords={displayTitle} leftAlignCarousel className={styles.recommendations}/>
  )
}

export default Recommendations