import { showAddToGarage, getCurrentFitmentDetails } from '/Utilities/garageUtilities'


const addCurrentVehicle = async (selectedFacetOptions, isBot, getGuid, selectedFacetData, updateCustomerProjects, addUserVehicle, loadingCallback, successCallback, failCallback) => {
    try {
        if (isBot()) {
            throw new Error('a bot attempted to add a vehicle to its garage')
        }
        var response = null;
        loadingCallback(true);
        const currentFitmentDetails = getCurrentFitmentDetails(selectedFacetOptions)
        response = await addUserVehicle(getGuid(), '/graphql/customerproject', currentFitmentDetails.year, currentFitmentDetails.makeId, currentFitmentDetails.modelId, currentFitmentDetails.raceTypeId, null, selectedFacetData)
        if (response?.addNewProjectToGarage?.success) {
            await updateCustomerProjects(response.addNewProjectToGarage.projects);
        } else {
            throw new Error('server response for adding vehicle to garage does not indicate success:' + response?.addNewProjectToGarage?.message)
        }

        successCallback(response?.addNewProjectToGarage?.projects?.[0]?.projectId)
    } catch (err) {
        console.error('error while adding vehicle to garage from sidebar', err)
        failCallback()
    }

    loadingCallback(false);
}

export default addCurrentVehicle