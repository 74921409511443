import Card from 'Clutch/Atoms/Card'
import Divider from 'Clutch/Atoms/Divider'
import Panel from 'Clutch/Atoms/Panel'
import styles from "./vehicleOptions.module.scss";
import Button from 'Clutch/Molecules/Button'
import Typography from 'Clutch/Atoms/Typography'
import Svg from 'Clutch/Atoms/Svg'
import Form from 'Clutch/Atoms/Form'
import Retry from 'Clutch/Utilities/Retry'
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import classNames from 'classnames'
import {createFitmentAddedSegmentEvent} from 'Clutch/Utilities/Instrumentation/Fitment/fitmentSegmentEvents'
import {createClickedSegmentEvent} from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import useVehicleSidebar from "../../hooks/useVehicleSidebar";
import useProductResultPage from "../../hooks/useProductResultPage";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AddUserVehicle } from 'Clutch/Contexts/VehicleContext/CoreVehicleRequests'
import { NotificationContext } from 'Clutch/Contexts/NotificationContext/NotificationContext'
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { usePrpStore } from '/contexts/prpContext'
import { showAddToGarage } from '/Utilities/garageUtilities'
import addCurrentVehicle from "../../Utilities/addCurrentVehicle";
import {useVehicleStore} from "Clutch/Stores/VehicleStore";
import AutoComplete from "Atoms/Form/Elements/AutoComplete/AutoComplete";

const VehicleOptions = () => {
    try {
        const [loading, setLoading] = useState(false)
        const [remainingYmmOptions, setRemainingYmmOptions] = useState([])
        const [selectedRemainingYmmOptions, setSelectedRemainingYmmOptions] = useState({})
        const [ymmCompletionLoading, setYmmCompletionLoading] = useState(false)
        const [navUrl, setNavUrl] = useState(false)
        const [projectIdToSelect, setProjectIdToSelect] = useState(false)

        const selectedFacetOptions = useProductResultPage(x => x.selectedFacetOptions)
        const searchResponseFacets = useProductResultPage(x => x.searchResponseFacets)
        const totalSearchResults = useProductResultPage(x => x.totalSearchResults)
        const spaLoadId = useProductResultPage(x => x.spaLoadId)
        const incompleteYmmPage = useProductResultPage(x => x.incompleteYmmPage)
        const currentPageLinkRequestJson = useProductResultPage(x => x.currentPageLinkRequestJson)
        const selectedFacets = selectedFacetOptions || [];
        const { vehicleName, isVehicleSelected } = useVehicleSidebar(selectedFacets);
        const showMobile = incompleteYmmPage
        const selectedFacetData = useVehicleStore(state => state.context.selectedFacetData)
        const customerProjects = useVehicleStore(state => state.context.customerProjects)
        const selectedCustomerProject = useVehicleStore(state => state.context.selectedCustomerProject)
        const isEngineOnlySelected = !!selectedCustomerProject?.engineDisplayName && !selectedCustomerProject?.makeId && !selectedCustomerProject?.raceTypeId

        const Navigate = usePrpStore(x => x.Navigate)
        const { PushNotification } = useContext(NotificationContext)
        const getGuid = useUserStore((x) => x.context.getGuid);
        const isBot = useUserStore((x) => x.context.isBot);
        const openAddOrSelectCustomerProjectExperience = useVehicleStore(state => state.openAddOrSelectCustomerProjectExperience)
        const updateCustomerProjects = useVehicleStore(state => state.updateCustomerProjects)
        const selectCustomerProject = useVehicleStore(state => state.selectCustomerProject)

        const yearRef = useRef();
        const modelRef = useRef();

        useEffect(() => {            
            if(yearRef?.current && selectedRemainingYmmOptions?.make && !selectedRemainingYmmOptions?.year) {
                yearRef.current.focus();
            }

            if(modelRef?.current && selectedRemainingYmmOptions?.year && selectedRemainingYmmOptions?.make && !selectedRemainingYmmOptions?.model) {
                modelRef.current.focus();
            }
        }, [selectedRemainingYmmOptions])

        useEffect(() => {
            const make = {
                id: selectedFacetOptions?.find(x => x.fitmentDetails?.make != null)?.fitmentDetails?.makeId,
                name: selectedFacetOptions?.find(x => x.fitmentDetails?.make != null)?.fitmentDetails?.make
            }
            setSelectedRemainingYmmOptions({make: make})
            setNavUrl(false)
        }, [spaLoadId])

        useEffect(() => {
            GetYearMakeModelData()

            async function GetYearMakeModelData() {
                const remainingYmmFacets = searchResponseFacets?.filter(x => x.facetName == "Model" || x.facetName == "Year")
                const makeName = selectedFacetOptions?.find(x => x.fitmentDetails?.make != null)?.fitmentDetails?.make;
                const makeId = selectedFacetOptions?.find(x => x.fitmentDetails?.makeId != null)?.fitmentDetails?.makeId;
                const modelId = selectedFacetOptions?.find(x => x.fitmentDetails?.modelId != null)?.fitmentDetails?.modelId;

                var module = await Retry(() => import('Contexts/VehicleContext/CoreVehicleRequests'))
                var makeResponse = makeName && await module.FetchMakes('/graphql/trait')
                var modelResponse = makeName ? await module.FetchModels(makeName, '/graphql/trait') : false
                var yearResponse = remainingYmmFacets?.find(x => x.facetName == "Year") ? remainingYmmFacets?.find(x => x.facetName == "Year") :
                    await module.FetchYearMakeModel({}, undefined, makeId, modelId, '/graphql/trait')

                setRemainingYmmOptions([
                        remainingYmmFacets?.find(x => x.facetName == "Year") ? {
                            name: "Year",
                            options: remainingYmmFacets?.find(x => x.facetName == "Year")?.searchResponseFacetOptions?.map(y => {
                                return {
                                    value: JSON.stringify({id: y.id, name: y.displayValue}),
                                    label: y.displayValue
                                }
                            })
                        } : {
                            name: "Year",
                            options: yearResponse?.ymm?.years?.map(y => {
                                return {
                                    value: JSON.stringify({id: y.optionId, name: y.displayName}),
                                    label: y.displayName
                                }
                            })
                        },
                        {
                            name: "Make",
                            options: makeResponse?.allMakes?.map(x => {
                                return {
                                    label: x.make,
                                    value: JSON.stringify({id: x.makeId, name: x.make})
                                }
                            })
                        },
                        remainingYmmFacets?.find(x => x.facetName == "Model") ?
                            {
                                name: "Model",
                                options: remainingYmmFacets?.find(x => x.facetName == "Model")?.searchResponseFacetOptions?.map(y => {
                                    return {
                                        value: JSON.stringify({id: y.id, name: y.displayValue}),
                                        label: y.displayValue
                                    }
                                })
                            }
                            :
                            {
                                name: "Model",
                                options: modelResponse?.models?.find(x => x.makeName == makeName)?.models?.map(x => {
                                    return {
                                        label: x.modelName,
                                        value: JSON.stringify({id: x.modelId, name: x.modelName})
                                    }
                                })
                            }
                    ]
                )

                const make = {
                    id: selectedFacetOptions?.find(x => x.fitmentDetails?.make != null)?.fitmentDetails?.makeId,
                    name: selectedFacetOptions?.find(x => x.fitmentDetails?.make != null)?.fitmentDetails?.make
                }
                const model = {
                    id: selectedFacetOptions?.find(x => x.fitmentDetails?.model != null)?.fitmentDetails?.modelId,
                    name: selectedFacetOptions?.find(x => x.fitmentDetails?.model != null)?.fitmentDetails?.model
                }
                setSelectedRemainingYmmOptions({make: make})
                if (model.name != null) {
                    const selectedOptions = {...selectedRemainingYmmOptions, model: model}
                    setSelectedRemainingYmmOptions(selectedOptions)
                }
            }
        }, [selectedFacetOptions])

        useEffect(() => {
            if(projectIdToSelect){
                selectCustomerProject(projectIdToSelect)
            }
        }, [projectIdToSelect])


        //remove once full migration is complete
        useEffect(() => {
            if(projectIdToSelect){
                selectCustomerProject(projectIdToSelect)
            }
        }, [customerProjects])

        const addVehicleToGarage = async () => {
            addCurrentVehicle(selectedFacetOptions, isBot, getGuid, selectedFacetData, updateCustomerProjects, AddUserVehicle, setLoading,
                (projectId) => {
                    PushNotification(`${vehicleName} added to your garage!`, "positive", 5000, null, {
                        intent: "postitive",
                        icon: "check"
                    });
                    setProjectIdToSelect(projectId)
                }, () => PushNotification(`Something went wrong, please try that again`, 'negative'))
        }

        const canAdd = showAddToGarage(selectedFacetOptions || [], customerProjects)
        const fitmentFacet = selectedFacetOptions?.find(x => x.displayText == "Fitment");

        let segmentAddEvent = fitmentFacet?.fitmentDetails ? createFitmentAddedSegmentEvent(
            fitmentFacet.fitmentDetails.makeId,
            fitmentFacet.fitmentDetails.make,
            fitmentFacet.fitmentDetails.modelId,
            fitmentFacet.fitmentDetails.model,
            fitmentFacet.fitmentDetails.year,
            fitmentFacet.fitmentDetails.raceTypeId,
            fitmentFacet.fitmentDetails.raceType
        ) : createClickedSegmentEvent('SelectVehiclePrp')

        const makeId = selectedFacetOptions?.find(x => x.fitmentDetails?.makeId != null)?.fitmentDetails?.makeId;
        const year = selectedFacetOptions?.find(x => x.fitmentDetails?.year != null)?.fitmentDetails?.year;

        async function GetUrlAndNavigate(e, selectedOptions) {
            const linkRequest = JSON.parse(currentPageLinkRequestJson)
            const linkRequests = [{
                ...linkRequest,
                year: selectedOptions?.year?.id || linkRequest?.year,
                make: selectedOptions?.make?.id || linkRequest?.make,
                model: selectedOptions.model?.id || linkRequest?.model
            }]
            const module = await Retry(() => import('/Utilities/buildLinks'))
            var response = await module.BuildLinks(linkRequests)

            if (response?.length) {
                const link = response[0]
                if (link.isValid) {
                    setNavUrl(link.url)
                    Navigate(e, link.url, setYmmCompletionLoading)
                } else {  //nav to ymm + search without offending facets
                    const sanitizedLinkRequests = [{
                        year: selectedOptions?.year?.id || linkRequest?.year,
                        make: selectedOptions?.make?.id || linkRequest?.make,
                        model: selectedOptions.model?.id || linkRequest?.model,
                        searchQuery: linkRequest?.searchQuery
                    }]

                    var sanitizedResponse = await module.BuildLinks(sanitizedLinkRequests)

                    if (sanitizedResponse?.length) {
                        const sanitizedLink = sanitizedResponse[0]
                        if (sanitizedLink.isValid) {
                            setNavUrl(sanitizedLink.url)
                            Navigate(e, sanitizedLink.url, setYmmCompletionLoading)
                        }
                    }
                }
            }
        }

        const handleOnChangeModel = (e) => {
            try {
                const selection = JSON.parse(e.value)
                const selectedOptions = {...selectedRemainingYmmOptions, model: selection}
                setSelectedRemainingYmmOptions({...selectedRemainingYmmOptions, model: selection});


                if (year || selectedOptions.year) {
                    GetUrlAndNavigate(e, selectedOptions)
                } else {
                    GetUpdatedVehicles()

                    async function GetUpdatedVehicles() {
                        var module = await Retry(() => import('Contexts/VehicleContext/CoreVehicleRequests'))
                        var response = await module.FetchYearMakeModel({}, undefined, makeId, selection.id, '/graphql/trait')

                        setRemainingYmmOptions([
                            ...remainingYmmOptions.filter(x => x.name != "Year"),
                            {
                                name: "Year",
                                options: response?.ymm?.years.map(x => {
                                    return {
                                        label: x.displayName,
                                        id: x.optionId,
                                        value: JSON.stringify({id: x.optionId, name: x.displayName})
                                    }
                                })
                            }])
                    }
                }
            } catch (err) {
                console.error('There was an error selecting a model on incomplete ymm page', err)
            }
        }

        const handleOnChangeYear = (e) => {
            try {
                const selection = JSON.parse(e.value)
                const selectedOptions = {...selectedRemainingYmmOptions, year: selection}
                setSelectedRemainingYmmOptions(selectedOptions);

                const selectorMakeId = selectedRemainingYmmOptions?.make?.id

                if (selectedOptions.model?.name) {
                    GetUrlAndNavigate(e, selectedOptions)
                } else {
                    GetUpdatedVehicles()

                    async function GetUpdatedVehicles() {
                        var module = await Retry(() => import('Contexts/VehicleContext/CoreVehicleRequests'))
                        var response = await module.FetchYearMakeModel({}, selection.id, selectorMakeId, undefined, '/graphql/trait')

                        setRemainingYmmOptions([
                            ...remainingYmmOptions.filter(x => x.name != "Model"),
                            {
                                name: "Model",
                                options: response?.ymm?.models.map(x => {
                                    return {
                                        label: x.displayName,
                                        id: x.optionId,
                                        value: JSON.stringify({id: x.optionId, name: x.displayName})
                                    }
                                })
                            }])
                    }
                }
            } catch (err) {
                console.error('There was an error completing ymm selection', err)
            }
        }

        const handleOnChangeMake = (e) => {
            try {
                const selection = JSON.parse(e.value)
                const selectedOptions = {...selectedRemainingYmmOptions, make: selection, model: {}}
                setSelectedRemainingYmmOptions(selectedOptions)
                GetUpdatedVehicles()

                const selectorYearId = selectedRemainingYmmOptions?.year?.id

                async function GetUpdatedVehicles() {
                    var module = await Retry(() => import('Contexts/VehicleContext/CoreVehicleRequests'))
                    if (selectedOptions.year?.id) {
                        var response = await module.FetchYearMakeModel({}, selectorYearId, selection.id, undefined, '/graphql/trait')

                        setRemainingYmmOptions([
                            ...remainingYmmOptions.filter(x => x.name != "Model"),
                            {
                                name: "Model",
                                options: response?.ymm?.models.map(x => {
                                    return {
                                        label: x.displayName,
                                        id: x.optionId,
                                        value: JSON.stringify({id: x.optionId, name: x.displayName})
                                    }
                                })
                            }])
                    } else {
                        var response = await module.FetchModels(selection.name, '/graphql/trait')

                        setRemainingYmmOptions([
                            ...remainingYmmOptions.filter(x => x.name != "Model"),
                            {
                                name: "Model",
                                options: response?.models?.find(x => x.makeName == selection.name)?.models?.map(x => {
                                    return {
                                        label: x.modelName,
                                        id: x.modelId,
                                        value: JSON.stringify({id: x.modelId, name: x.modelName})
                                    }
                                })
                            }])
                    }
                }
            } catch (err) {
                console.error('There was an error completing ymm selection', err)
            }
        }

        const yearOptions = findAndSortTheOptions("Year", remainingYmmOptions)
        const makeOptions = findAndSortTheOptions("Make", remainingYmmOptions)
        const modelOptions = findAndSortTheOptions("Model", remainingYmmOptions)

        return (
            <Card fill noPadding layer={!isVehicleSelected ? 0 : 1}
                  className={classNames(styles.card, showMobile ? styles.showMobile : styles.hideMobile)}>
                <div className={!isVehicleSelected ? styles.cardContentsNoVehicle : styles.cardContents}>
                    <div className={styles.title}>
                        <>
                            {(isVehicleSelected && incompleteYmmPage &&
                                <>
                                    <Svg icon={"car"} size={1.25} className={styles.svg}/>
                                    <Typography className={styles.exactFitText}>Get an exact fit for your </Typography>
                                    <Typography className={styles.vehicleText} font={"bold"}>{vehicleName}</Typography>
                                </>)}
                            {(!isVehicleSelected && !isEngineOnlySelected &&
                                <div className={styles.spaceBetween}>
                                    <div className={styles.itemText}>
                                        <Svg icon={"car"} size={1.25} className={styles.svg}/>
                                        <Typography className={styles.exactFitText}>Find Parts That Fit Your
                                            Vehicle</Typography>
                                    </div>
                                    <Button brand={"secondary"} size={"small"} className={styles.changeSelected}
                                            onClick={() => openAddOrSelectCustomerProjectExperience(true)}
                                            segmentEvent={createClickedSegmentEvent('PrpSelectVehicle')}
                                            testingName={'PrpSelectVehicle'}>
                                        <Typography font={"bold"}>Select A Vehicle</Typography>
                                    </Button>
                                </div>)}
                            {(((isVehicleSelected && !incompleteYmmPage) || isEngineOnlySelected) &&
                                <div className={styles.spaceBetween}>
                                    <div className={styles.itemText}>
                                        <Panel layer={0} className={styles.svg}>
                                            {totalSearchResults.toString() == "0" ?
                                                <Svg icon={"warning-circle"} size={2} intent={"negative"}/>
                                                : <Svg icon={"check"} size={2} intent={"positive"}/>}
                                        </Panel>
                                        <Typography>{totalSearchResults} items that fit your: </Typography>
                                        <Typography className={styles.completeVehicleText} font={"bold"}>
                                            {isEngineOnlySelected 
                                                ? selectedCustomerProject?.engineDisplayName
                                                : vehicleName}
                                        </Typography>
                                    </div>
                                    <div className={styles.buttonWrapper}>
                                        <Button intent={"subdued"} size={"small"} className={styles.changeSelected}
                                                onClick={() => openAddOrSelectCustomerProjectExperience(true)}
                                                segmentEvent={createClickedSegmentEvent('PrpChangeVehicle')}
                                                testingName={'ChangeVehicle'}>
                                            <Typography font={"bold"}>Change Selected</Typography>
                                        </Button>
                                        {canAdd &&
                                            <Button isWaiting={loading} brand={"secondary"} size={"small"}
                                                    className={styles.vehicleButton} segmentEvent={segmentAddEvent}
                                                    onClick={addVehicleToGarage} testingName={'PrpAddToGarage'}>
                                                <Typography font={"bold"}>Add To Garage</Typography>
                                            </Button>}
                                    </div>
                                </div>)}
                        </>
                    </div>
                    {incompleteYmmPage ? (
                            <>
                                <Divider className={styles.divider}/>
                                <div className={styles.completeYourVehicle}>
                                    <CoveringSpinner isLoading={ymmCompletionLoading} fillWidth={true}
                                                     className={styles.completeVehicleSelectors}>
                                        <div className={styles.selectWrapper}>
                                            <AutoComplete
                                                options={yearOptions}
                                                placeholder={selectedRemainingYmmOptions.year?.name ?? fitmentFacet?.fitmentDetails?.year ?? "Choose Year"}
                                                onChange={handleOnChangeYear}
                                                label={"Year"}
                                                ref={yearRef}
                                                isDisabled={!yearOptions?.length}
                                            />
                                        </div>
                                        <div className={styles.selectWrapper}>
                                            <AutoComplete
                                                options={makeOptions}
                                                placeholder={selectedRemainingYmmOptions.make?.name ?? fitmentFacet?.fitmentDetails?.make ?? "Choose Make"}
                                                onChange={handleOnChangeMake}
                                                label={"Make"}
                                                isDisabled={!makeOptions?.length}
                                            />
                                        </div>
                                        <div className={styles.selectWrapper}>
                                            <AutoComplete
                                                options={modelOptions}
                                                placeholder={selectedRemainingYmmOptions.model?.name ?? fitmentFacet?.fitmentDetails?.model ?? "Choose Model"}
                                                onChange={handleOnChangeModel}
                                                label={"Model"}
                                                ref={modelRef}
                                                isDisabled={!modelOptions?.length}
                                            />
                                        </div>
                                        <Button intent={"action"} size={"small"} className={styles.viewResults}
                                                href={navUrl ?? '/'} disabled={!navUrl} segmentEvent={segmentAddEvent}
                                                testingName={'PrpViewVehicleResults'}>
                                            <Typography font={"bold"}>View Results</Typography>
                                        </Button>
                                    </CoveringSpinner>
                                </div>
                            </>
                        )
                        : false}
                </div>
            </Card>
        )
    } catch (err) {
        console.error('There was an issue rendering the vehicle options section', err)
    }
}

const findAndSortTheOptions = (name, remainingYmmOptions) => {
    if (name == "Year") return remainingYmmOptions.find(x => x.name == name)?.options?.sort((a, b) => b.label.localeCompare(a.label))
    return remainingYmmOptions.find(x => x.name == name)?.options?.sort((a, b) => a.label.localeCompare(b.label))
}

export default VehicleOptions